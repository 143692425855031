<template lang="pug">
  .candidates-email
    handleFailedCandidatesProvider(v-slot="handleFailed")
      span
        v-dialog(:value="handleFailed.showing" @input="handleFailed.toggle" scrollable)
          handleFailedBody(
            :list="handleFailed.failedCandidates"
            :error="handleFailed.error"
            :totalSendCount="handleFailed.totalSendCount"
            @input="handleFailed.toggle"
          )
        span
          baseEmailProvider(v-slot="baseEmail")
            bulkEmailProvider(
              v-slot="bulkEmail"
              :formErrors="baseEmail.formErrors"
              :activeItems="activeItems"
              @loading:start="baseEmail.toggleProcessing"
              @loading:end="baseEmail.toggleProcessing"
              @send="handleFailed.check($event, activeItems.length)"
            )
              EmailTemplates(
                :items="baseEmail.list"
                :itemsLoading="baseEmail.listLoading"
                :editedTemplate="baseEmail.editedTemplate"
                :loadData="baseEmail.loadData"
                :logs="baseEmail.logs"
                :formErrors="baseEmail.formErrors"
                :processing="baseEmail.processing"
                :maxItemsShowing="11"
                @templateSelected="baseEmail.loadTemplateData"
                @onAddCustomTemplate="baseEmail.loadCreateTemplate"
                @submit="bulkEmail.sendEmail"
                @save="baseEmail.post"
                @update="baseEmail.update"
                @remove="baseEmail.remove"
              )
                template(v-slot:activator="emailActivator")
                  v-btn(
                    color="primary"
                    small
                    outlined
                    :disabled="!activeItems.length"
                    @click="emailActivator.toggle"
                  ) Email
</template>

<script>
export default {
  props: {
    activeItems: {
      required: true,
      type: Array
    }
  },

  components: {
    baseEmailProvider: () => import('./candidates/CandidatesEmailBaseProvider.vue'),
    bulkEmailProvider: () => import('./candidates/CandidatesEmailBulkProvider.vue'),
    EmailTemplates: () => import('./candidates/CandidatesEmailTemplates.vue'),
    handleFailedCandidatesProvider: () => import('./CandidatesHandleFailedProvider.vue'),
    handleFailedBody: () => import('./CandidatesHandleFailedBody.vue'),
  }
}
</script>
